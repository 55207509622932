import axios from 'axios';

//const baseURL = process.env.BASE_API_URL || 'http://localhost:8000/v1/';
const baseURL = '/v1/';

const HTTP = axios.create({
  baseURL,
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
});

const errorHandler = (error) => {
  if (error.response && error.response.status === 401) {
    window.location = `${baseURL.replace('/v1/', '/login/')}`;
  }
  return Promise.reject(error);
};

HTTP.interceptors.response.use(response => response, errorHandler);

const api = {
  getToken: () => HTTP.get('token/').then(r => r.data),
  loginUser: (email, username, password) => HTTP.post('login/', { email, username, password }).then(r => r.data),
  fetchProfile: () => HTTP.get('profile/').then(r => r.data),
  updateProfile: (email, country, phone, timezone, name, password) => HTTP.post('profile/', { email, country, phone, timezone, name, password }).then(r => r.data),
  deleteProfile: () => HTTP.delete(`profile/`).then(r => r.data),


  fetchVehiclePreferences: () => HTTP.get('vehicle-preferences/').then(r => r.data),
  createVehiclePreferences: (color, v_class, numSeats, make, model, monthly, budget, _new, used) => HTTP.post('vehicle-preferences/', {
    color, v_class, numSeats, make, model, monthly, budget, new: _new, used,
  }).then(r => r.data),
  updateVehiclePreferences: (uid, color, v_class, numSeats, make, model, monthly, budget, _new, used) => HTTP.post(`vehicle-preferences/${uid}/`, {
    color, v_class, numSeats, make, model, monthly, budget, new: _new, used,
  }).then(r => r.data),

  fetchVehicles: () => HTTP.get('vehicles/').then(r => r.data),
  lookupVehicle: (vin, zipcode, state, plate) => HTTP.post('vehicles/lookup/', {
    vin, zipcode, state, plate,
  }).then(r => r.data),
  createVehicle: (vin, zipcode, state, plate, ymmt, version) => HTTP.post('vehicles/', {
    vin, zipcode, state, plate, ymmt, version,
  }).then(r => r.data),
  findVehicle: (zipcode, query, oauth2_provider) => HTTP.post('search/', {
    zipcode, query, oauth2_provider,
  }).then(r => r.data),

  fetchVehicle: id => HTTP.get(`vehicles/${id}/`).then(r => r.data),
  updateVehicle: (uid, make, model, color, year, mileage, condition, trim) => HTTP.post(`vehicles/${uid}/`, {
    make, model, color, year, mileage, condition, trim,
  }).then(r => r.data),
  deleteVehicle: id => HTTP.delete(`vehicles/${id}/`),

  fetchConversations: uid => HTTP.get(`vehicles/${uid}/conversations/`).then(r => r.data),
  fetchMessages: uid => HTTP.get(`conversations/${uid}/`).then(r => r.data),
  addMessage: (uid, kind, imageUrl, documentUrl, message) => HTTP.post(`conversations/${uid}/`, {
    kind, imageUrl, documentUrl, message,
  }).then(r => r.data),
  rateMessage: (uid, rating) => HTTP.post(`messages/${uid}/`, {
    rating,
  }).then(r => r.data),
  deleteMessage: uid => HTTP.delete(`messages/${uid}/`),
  viewedConversation: (uid) => HTTP.post(`conversations/${uid}/viewed/`, {}).then(r => r.data),

  likeVehicle: id => HTTP.post(`vehicles/${id}/like/`),
  unlikeVehicle: id => HTTP.post(`vehicles/${id}/un-like/`),
};

export default api;
