import '../scss/main.scss';

import api from './api';
import loadCardForm from './card';

const ready = (fn) => {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

window.api = api;
window.ready = ready;

ready(loadCardForm);
